<template>
  <div>
    <!-- Pajak Belum Dibayar -->
    <b-card
      title="Pajak Belum Dibayar"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              @click="addPajakSidebar = true"
            >
              + Tambah Data Pajak
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rows"
        responsive
        :fields="columns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPage"
        :filter="searchQuery"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Biaya -->
        <template #cell(jumlah)="data">
          <span class="text-primary font-weight-bolder">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="data.value === 'sudah_dibayar' ? 'light-success' : 'light-danger'"
          >
            {{ data.value === 'sudah_dibayar' ? 'Sudah Dibayar' : 'Belum Dibayar' }}
          </b-badge>
        </template>

        <template #cell(action)="data">

          <div class="text-nowrap">
            <b-button-group>
              <b-button
                :id="`bus-row-${data.item.id}-bayar-icon`"
                size="sm"
                variant="info"
                @click="bayarPajak(data.item.id, true)"
              >
                Bayar
              </b-button>
              <b-tooltip
                title="Bayar"
                class="cursor-pointer"
                :target="`bus-row-${data.item.id}-bayar-icon`"
              />

              <b-button
                :id="`bus-row-${data.item.id}-edit-icon`"
                size="sm"
                variant="warning"
                @click="editPajak(data.item, true)"
              >
                Edit
              </b-button>
              <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`bus-row-${data.item.id}-edit-icon`"
              />

              <b-button
                :id="`bus-row-${data.item.id}-jual-icon`"
                size="sm"
                variant="danger"
                @click="removeData(data.item.id)"
              >
                Hapus
              </b-button>
              <b-tooltip
                title="Hapus"
                :target="`bus-row-${data.item.id}-jual-icon`"
              />
            </b-button-group>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPajak"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <!-- Pajak Sudah Dibayar -->
    <b-card
      title="Pajak Sudah Dibayar"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Entries</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuerySudahDibayar"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="rowsSudahDibayar"
        responsive
        :fields="columnsSudahDibayar"
        primary-key="id"
        :sort-by.sync="sortBy"
        :per-page="perPage"
        :current-page="currentPageSudahDibayar"
        :filter="searchQuerySudahDibayar"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >

        <!-- Column: Id -->
        <template #cell(id)="data">
          <span class="text-primary font-weight-bolder">#{{ data.value }}</span>
        </template>

        <!-- Column: Biaya -->
        <template #cell(jumlah)="data">
          <span class="text-primary font-weight-bolder">{{ data.value | formatRupiah }}</span>
        </template>

        <!-- Column: Bukti -->
        <template #cell(bukti_pembayaran)="data">
          <b-img
            v-if="data.value"
            :src="data.value"
            height="125"
            alt="Responsive image"
          />
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="data.value === 'sudah_dibayar' ? 'light-success' : 'light-danger'"
          >
            {{ data.value === 'sudah_dibayar' ? 'Sudah Dibayar' : 'Belum Dibayar' }}
          </b-badge>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          <!-- <span class="text-muted">Showing entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalPajak"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <AddContent
      v-model="addPajakSidebar"
      :pajak="dataEditPajak"
      @pajak-sidebar-change="editPajak"
      @submit-data-pajak="submitData"
    />

    <BayarContent
      v-model="bayarPajakSidebar"
      :pajak="idBayarPajak"
      @pajak-sidebar-change="bayarPajak"
      @submit-bayar-pajak="submitPajak"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BImg,
  BPagination, BTooltip, BButtonGroup, BBadge,
  VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import AddContent from './Add.vue'
import BayarContent from './Bayar.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BTooltip,
    BButtonGroup,
    BImg,
    BBadge,

    AddContent,
    BayarContent,
    vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 3,
      dir: false,
      columns: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Nama Pajak',
          key: 'nama',
          sortable: true,
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
          sortable: true,
        },
        {
          label: 'Tenggat',
          key: 'jatuh_tempo',
          sortable: true,
        },
        {
          label: 'Biaya',
          key: 'jumlah',
          sortable: true,
        },
        {
          label: 'Status Bayar',
          key: 'status',
          sortable: true,
        },
        {
          label: 'Actions',
          key: 'action',
        },
      ],
      columnsSudahDibayar: [
        {
          label: 'ID',
          key: 'id',
          sortable: true,
        },
        {
          label: 'Nama Pajak',
          key: 'nama',
          sortable: true,
        },
        {
          label: 'Keterangan',
          key: 'keterangan',
          sortable: true,
        },
        {
          label: 'Tenggat',
          key: 'jatuh_tempo',
          sortable: true,
        },
        {
          label: 'Biaya',
          key: 'jumlah',
          sortable: true,
        },
        {
          label: 'Bukti Pembayaran',
          key: 'bukti_pembayaran',
          sortable: true,
        },
        {
          label: 'Status Bayar',
          key: 'status',
          sortable: true,
        },
      ],
      perPage: 5,
      totalPajak: 0,
      currentPage: 1,
      currentPageSudahDibayar: 1,
      perPageOptions: [5, 10, 25, 50],
      searchQuery: '',
      searchQuerySudahDibayar: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      rowsData: [],
      searchTerm: '',
      statusOptions: [
        'Aktif',
        'Tidak Aktif',
      ],
      addPajakSidebar: false,
      bayarPajakSidebar: false,
      idBayarPajak: null,
      dataEditPajak: {
        id: null,
        name: null,
        seat: null,
        seat_conf: null,
        status: 0,
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    rows() {
      return this.rowsData.filter(data => data.status === 'belum_dibayar')
    },
    rowsSudahDibayar() {
      return this.rowsData.filter(data => data.status === 'sudah_dibayar')
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$http.get(`/pajak/${this.$route.params.id}`).then(response => {
        this.rowsData = response.data.data
        this.totalPajak = response.data.data.length
      })
    },
    editPajak(data, value) {
      this.dataEditPajak = data
      this.addPajakSidebar = value
    },
    bayarPajak(id, value) {
      this.idBayarPajak = id
      this.bayarPajakSidebar = value
    },
    submitData(data) {
      if (data.id === null) {
        const dataPajak = {
          id_bis: this.$route.params.id,
          nama: data.nama,
          keterangan: data.keterangan,
          jumlah: data.jumlah,
          jatuh_tempo: data.jatuh_tempo,
          status: data.status,
        }
        this.$http.post('pajak', dataPajak).then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.getData()
        })
        this.dataEditPajak = {
          id: null,
          name: null,
          tenggat: null,
          biaya: null,
          bukti_pembayaran: null,
          status_bayar: null,
        }
      } else {
        const dataPajak = {
          id: data.id,
          nama: data.nama,
          keterangan: data.keterangan,
          jatuh_tempo: data.jatuh_tempo,
          jumlah: data.jumlah,
          status: data.status,
          _method: 'PUT',
        }
        this.$http.post(`/pajak/${data.id}`, dataPajak).then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.data.message,
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.getData()
        })
      }
      this.addPajakSidebar = false
    },
    submitPajak(data) {
      const dataPajak = new FormData()
      dataPajak.append('id', data.id)
      dataPajak.append('foto_nota', data.bukti_pembayaran)
      dataPajak.append('jatuh_tempo_selanjutnya', data.jatuh_tempo)
      dataPajak.append('jumlah_selanjutnya', data.jumlah)
      this.$http.post(`pajak-pembayaran/${data.id}`, dataPajak).then(response => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'BellIcon',
            variant: 'success',
          },
        })

        this.getData()
      })
      this.bayarPajakSidebar = false
    },
    removeData(id) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`/pajak/${id}`).then(response => {
            if (response.data.status === true) {
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.getData()
            } else {
              this.$swal({
                icon: 'danger',
                title: '-',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
</style>
