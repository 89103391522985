var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{attrs:{"id":"add-bus-sidebar","sidebar-class":"sidebar-lg","bg-variant":"white","visible":_vm.addServiceSidebar,"right":"","no-header":"","backdrop":""},on:{"hidden":function (val) { return _vm.changeValue(val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.pajakLocal.id > 0 ? 'Edit Pajak' : 'Add Pajak')+" ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"simpleRules",staticClass:"px-2"},[_c('b-form',[_c('b-row',{staticClass:"px-2"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Nama Pajak","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Nama Pajak","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false:null,"placeholder":"Nama Pajak"},model:{value:(_vm.pajakLocal.nama),callback:function ($$v) {_vm.$set(_vm.pajakLocal, "nama", $$v)},expression:"pajakLocal.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Keterangan","label-for":"keterangan"}},[_c('validation-provider',{attrs:{"name":"Keterangan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"keterangan-pajak","placeholder":"Keterangan","rows":"3"},model:{value:(_vm.pajakLocal.keterangan),callback:function ($$v) {_vm.$set(_vm.pajakLocal, "keterangan", $$v)},expression:"pajakLocal.keterangan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Tenggat","label-for":"tenggat"}},[_c('validation-provider',{attrs:{"name":"Tenggat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"id":"tenggat","state":errors.length > 0 ? false:null},model:{value:(_vm.pajakLocal.jatuh_tempo),callback:function ($$v) {_vm.$set(_vm.pajakLocal, "jatuh_tempo", $$v)},expression:"pajakLocal.jatuh_tempo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Biaya","label-for":"biaya"}},[_c('validation-provider',{attrs:{"name":"Biaya","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"biaya","state":errors.length > 0 ? false:null,"placeholder":"Biaya"},model:{value:(_vm.pajakLocal.jumlah),callback:function ($$v) {_vm.$set(_vm.pajakLocal, "jumlah", $$v)},expression:"pajakLocal.jumlah"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.pajakLocal.id === null)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Status Bayar","label-for":"status-status"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"status-status","options":_vm.options,"state":errors.length > 0 ? false:null,"placeholder":"Status"},model:{value:(_vm.pajakLocal.status),callback:function ($$v) {_vm.$set(_vm.pajakLocal, "status", $$v)},expression:"pajakLocal.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Submit ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":function($event){return _vm.changeValue(false)}}},[_vm._v(" Cancel ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }